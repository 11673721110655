import 'date-fns';
import React, {useContext, useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Types} from "../_context/AppTypes";

import AppBar from '@material-ui/core/AppBar';

import {useHistory, useParams} from "react-router-dom";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TuneIcon from '@material-ui/icons/Tune';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TextField from '@material-ui/core/TextField';
import {AppContext} from '../_context/AppState';
import useRoutingControl from "../_hook/RoutingControl";
import UserService from "../_services/user.service";
import Vehicles from './Vehicles';
import {RoutingMachineID} from "../_constants/const";
import Fab from "@material-ui/core/Fab";
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import {LatLng} from "leaflet";
import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import GeoException from "./GeoException";


function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },

        fabButtonCancel: {
            zIndex: 1000000,
            position: 'fixed',
            bottom: '10px',
            left: '175px',
        },
        fabButtonSave: {
            zIndex: 1000000,
            position: 'fixed',
            bottom: '10px',
            left: '295px',
        },
        leftContainer: {
            height: 'calc(100vh - 64px)',
            overflow: 'hidden',
        },
        tabPanel: {
            padding: "10px",
        },
        routingMachine: {
            width: '100%',
            minWidth: '380px',
        },
        TabAction: {
            minWidth: '100px',
        }
    }),
);

export enum TabPanelValue {
    Routing = "1",
    Vehicle = "2",
    Exception = "3",
}

const Plan: React.FC = () => {
    const classes = useStyles();

    // get global state
    const {state, dispatch, route, setRoute, mapRef, drawControlRef, drawnItemsRef} = useContext(AppContext);
    /**
     * USESTATE AT TOP LEVEL
     */
        // State change tabPanel
    const [messageSnackbar, setMessageSnackbar] = useState("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const [valueTab, setValueTab] = useState("1");

    const [planName, setPlanName] = useState("");

    const [waypoints, setWaypoints] = useState<LatLng[]>([]);
    // using history router
    let history = useHistory();

    const {id} = useParams();
    let currentPlanID = id ? id : 0; // default is NEWPLAN

    // init routingControluseRoutingControl
    //let waypoints = state.currentPlan ? state.currentPlan.waypoints : [];

    const [routingControl, appendChildRouting] = useRoutingControl(RoutingMachineID, waypoints);

    const handleSavePlan = () => {
        // Update PlanName
        state.currentPlan.name = planName;

        if (route) {
            if (route.summary) {
                state.currentPlan.totalSecond = Math.trunc(route.summary.totalTime);
                state.currentPlan.totalDistance = route.summary.totalDistance;
                state.currentPlan.description = route.name;
            }
            if (route.inputWaypoints) {
                state.currentPlan.waypoints = route.inputWaypoints; //use inputWaypoints from user
            }
            if (route.coordinates) {
                state.currentPlan.coordinates = route.coordinates;
            }
            if (route.instructions) {
                state.currentPlan.instructions = JSON.stringify(route.instructions);
            }

        }

        if (currentPlanID == 0) {
            // create new plan
            UserService.postPlans(state.currentPlan).then(
                (data) => {
                    setMessageSnackbar("Save Successful!");
                    setOpenSnackbar(true);

                    dispatch({
                        type: Types.UpdatePlan, payload: {
                            id: data.id,
                        }
                    });
                },
                (error) => {
                    setMessageSnackbar("Save Error. Please try again!");
                    setOpenSnackbar(true);
                }
            );
        } else {
            // update plan
            UserService.putPlans(state.currentPlan).then(
                (data) => {
                    setMessageSnackbar("Save Successful!");
                    setOpenSnackbar(true);

                },
                (error) => {
                    setMessageSnackbar("Save Error. Please try again!");
                    setOpenSnackbar(true);
                }
            );
        }
    };

    // #######TABPANEL
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        /* if (planNameRef.current){
             dispatch({type: Types.UpdatePlan, payload: {
                     name: planNameRef.current.value,
                 }});
         };*/
        if (newValue == TabPanelValue.Exception) {
            if (drawControlRef.current)
                mapRef.current.addControl(drawControlRef.current);
        } else {
            if (drawControlRef.current)
                mapRef.current.removeControl(drawControlRef.current);
            if (drawnItemsRef.current)
                drawnItemsRef.current.clearLayers();
        }

        setValueTab(newValue);

        // TODO when change table not load routingCountrol machine
        setTimeout(function log() {
            const domRM = document.getElementById(RoutingMachineID);
            if ((valueTab === TabPanelValue.Vehicle || valueTab === TabPanelValue.Routing) && routingControl.current && domRM && domRM.childElementCount == 0) {
                appendChildRouting();
            }
        }, 2000);
    };

    const handleChangePlanName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanName(event.target.value);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        // Step 1: load detail plan
        if (currentPlanID) {
            UserService.getPlanDetail(currentPlanID).then(
                (data) => {
                    // TODO how to inject name?
                    setPlanName(data.name);

                    setWaypoints(data.waypoints);

                    dispatch({type: Types.GetPlan, payload: data})
                },
                (error) => {
                    console.log("UserService.getPlanWaypoints():", error)
                }
            );
        } else {
            //TODO: when new plan
            dispatch({type: Types.CreatePlan})
        }

        // Step 2: load module RoutingMachine
        const domRM = document.getElementById(RoutingMachineID);

        if (valueTab === TabPanelValue.Routing && routingControl.current && domRM && domRM.childElementCount == 0) {
            appendChildRouting();
        }

        return () => {
            dispatch({type: Types.EmptyPlan})

            setRoute({});
            if (routingControl.current) {
                // remove oldwaypoints
                routingControl.current.setWaypoints([]);
                // remove lines
                routingControl.current.remove();
            }

            if (drawControlRef.current)
                mapRef.current.removeControl(drawControlRef.current);
            if (drawnItemsRef.current)
                drawnItemsRef.current.clearLayers();

        };
    }, []);

    return <div className={classes.root}>
        <TabContext value={valueTab}>
            <AppBar position="relative" color={"default"}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => history.push('/')}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <TabList onChange={handleChangeTab} aria-label="simple tabs example">
                        <Tab className={classes.TabAction} value={TabPanelValue.Routing} icon={<ShowChartIcon/>}/>
                        <Tab className={classes.TabAction} value={TabPanelValue.Vehicle} icon={<TuneIcon/>}/>
                        <Tab className={classes.TabAction} value={TabPanelValue.Exception}
                             icon={<NotificationsOffIcon/>}/>
                    </TabList>
                </Toolbar>

            </AppBar>
            <div className={classes.leftContainer}>
                <Alert severity="warning">Under Construction!</Alert>
                <TabPanel value={TabPanelValue.Routing} className={classes.tabPanel}>
                    <form noValidate autoComplete="off">
                        <div>
                            <TextField required id="plan-name" label="Plan Name" value={planName}
                                       onChange={handleChangePlanName} fullWidth/>
                        </div>
                    </form>

                    <div id={"routingMachine"} className={classes.routingMachine}></div>
                </TabPanel>
                <TabPanel value={TabPanelValue.Vehicle}>
                    <Vehicles/>
                </TabPanel>
                <TabPanel value={TabPanelValue.Exception}>
                    <GeoException></GeoException>
                </TabPanel>
                <Fab onClick={() => history.push('/')} variant="extended" size="medium" color="primary" aria-label="add"
                     className={classes.fabButtonCancel}>
                    <CloseIcon/>
                    Cancel
                </Fab>
                <Fab onClick={handleSavePlan} variant="extended" size="medium" color="secondary" aria-label="add"
                     className={classes.fabButtonSave}>
                    <SaveIcon/>
                    Save
                </Fab>
            </div>
        </TabContext>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="info">
                {messageSnackbar}
            </Alert>
        </Snackbar>
    </div>
}
export default Plan;
