import React, {useContext} from 'react';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AssessmentIcon from "@material-ui/icons/Assessment";
import UserService from "../_services/user.service";
import {Types} from "../_context/AppTypes";
import {AppContext} from "../_context/AppState";
import NotificationsIcon from '@material-ui/icons/Notifications';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const ITEM_HEIGHT = 48;

type Props = {
    currentPlanIDMenu: Number;
    anchorElMenu: HTMLElement | null;
    handleCloseMenu: any;
}

const ActionMenu: React.FC<Props> = ({currentPlanIDMenu, anchorElMenu, handleCloseMenu}) => {
    const {dispatch, setPlanReportModal, setAlertReportModal, setSessionsModal} = useContext(AppContext);
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const openMenu = Boolean(anchorElMenu);

    const handleMenuDeletePlan = () => {
        if (currentPlanIDMenu != 0) {
            handleDeletePlan(currentPlanIDMenu);
        }
        handleCloseMenu();
        setOpenConfirm(false);
    }

    const handleDeletePlan = (id: Number) => {
        UserService.deletePlans(id).then(
            (data) => {
                dispatch({type: Types.DeletePlan, payload: {id}})
            },
            (error) => {
                console.log("UserService.postPlans():", error)
            }
        );
    }

    const handleMenuReportPlan = () => {
        handleCloseMenu();
        setPlanReportModal(currentPlanIDMenu);
    }

    const handleMenuReportAlert = () => {
        handleCloseMenu();
        setAlertReportModal(currentPlanIDMenu);
    }

    const handleMenuGetSessions = () => {
        handleCloseMenu();
        setSessionsModal(currentPlanIDMenu);
    }

    const handleClickOpenConfirm = () => {
        handleCloseMenu();
        setOpenConfirm(true);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    return (
        <React.Fragment>
            <Menu id="long-menu"
                  anchorEl={anchorElMenu}
                  keepMounted
                  open={openMenu}
                  onClose={handleCloseMenu}
                  PaperProps={{
                      style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch',
                      },
                  }}>
                <MenuItem onClick={handleMenuGetSessions}>
                    <ListItemIcon>
                        <VerifiedUserIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant="inherit">Sessions</Typography>
                </MenuItem>
                <Divider></Divider>
                <MenuItem onClick={handleMenuReportAlert}>
                    <ListItemIcon>
                        <NotificationsIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant="inherit">Alert Report</Typography>
                </MenuItem>
                <MenuItem onClick={handleMenuReportPlan}>
                    <ListItemIcon>
                        <AssessmentIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography variant="inherit">Booking</Typography>
                </MenuItem>
            </Menu>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Plan"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you delete your Plan, it cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleMenuDeletePlan} variant="contained" color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ActionMenu;