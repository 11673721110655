import React, {useContext} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AppContext} from "../_context/AppState";
import {SessionType, PlanType} from "../_context/AppTypes";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import UserService from "../_services/user.service";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {ReportModal} from '../_constants/const';
import TextField from '@material-ui/core/TextField';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Column {
    id: 'orderId' | 'deviceID' | 'rfID' | 'expiredAt' | 'expiredDate' | 'action';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
    isLinkAddr?: () => boolean;
}

const columns: Column[] = [
    {
        id: 'orderId',
        label: 'No',
        align: 'right',
    },
    {id: 'deviceID', label: 'Device ID'},
    {id: 'rfID', label: 'RfID'},
    {id: 'expiredAt', label: 'ExpiredAt'},
    {id: 'expiredDate', label: 'ExpiredDate'},
    {id: 'action', label: 'Action'},
];


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        container: {
            minHeight: '80vh',
            maxHeight: '80vh',
        },
        dateContainer: {
            width: '140px',
        },
        reportControlItem: {
            margin: '10px',
        },
        reportControl: {
            flexGrow: 1,
        },
        reportSelectPlan: {
            width: '300px',
        },
        dateTimeField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width: 200,
        },
        buttonSuccess: {
          backgroundColor: green[500],
          '&:hover': {
            backgroundColor: green[700],
          },
        },
        buttonProgress: {
          color: green[500],
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        },
    }),
);

export default function SessionsDialogs() {
    const classes = useStyles();

    const {state, sessionsModal, setSessionsModal} = useContext(AppContext);

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataReport, setDataReport] = React.useState<SessionType[]>([]);

    const [selectedExpiredDate, setSelectedExpiredDate] = React.useState<Date | null>(new Date());;

    const [inputRfID, setInputRfID] = React.useState<String | null>("");

    const [messageSnackbar, setMessageSnackbar] = React.useState("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false);


    const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleRfIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    	console.log("...........handleRfIDChange", event.target.value);
        setInputRfID(event.target.value);
    };

    const handleSelectedExpiredDate = (date: Date | null) => {
        console.log("...........handleSelectedExpiredDate", date);

        setSelectedExpiredDate(date);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setSessionsModal(ReportModal.NOT_OPEN);
    };

    const handleReport = (planID: Number) => {
        if (planID > ReportModal.NOT_OPEN) {
            setSuccess(false);
            setLoading(true);
            UserService.getSessions(planID).then(
                (data) => {
                    data.sessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
                        row.deviceID = String(planID);
                    });
                    setDataReport(data.sessions);
                    setSuccess(true);
                    setLoading(false);
                },
                (error) => {
                    console.log("UserService.getReport():", error)
                    setMessageSnackbar("Error!");
                    setOpenSnackbar(true); 
                    setSuccess(true);
                    setLoading(false);
                }
            );
        }
    }

    const handleSetSession = (planID: Number ) => {
        if(inputRfID != null && inputRfID.length != 10 ){
            setMessageSnackbar("RfID invalid:" + inputRfID);
            setOpenSnackbar(true); 
            return 
        }

        if( moment(selectedExpiredDate).unix() < moment().unix() ){
            setMessageSnackbar("ExpiredDate invalid:" + moment(selectedExpiredDate).format("yyyy-MM-DD HH:mm"));
            setOpenSnackbar(true); 
            return 
        }

        setSuccess(false);
        setLoading(true);
         
        UserService.setSession(planID, inputRfID,  moment(selectedExpiredDate).unix()).then(
            (data) => {
                var isExist = false;
                dataReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    console.log("row", row);
                    if(row.rfID === inputRfID){
                        isExist = true;
                        row.expiredDate = moment(selectedExpiredDate).format("yyyy/MM/DD HH:mm:ss");
                    }
                })
                if(isExist == false){
                    let aNew: SessionType = {
                        orderId: 0,
                        action: "Delete",
                        deviceID: String(planID),
                        rfID: String(inputRfID),
                        expiredAt: moment(selectedExpiredDate).unix(),
                        expiredDate: moment(selectedExpiredDate).format("yyyy/MM/DD HH:mm:ss"),
                    };
                    dataReport.push(aNew);
                }

                setMessageSnackbar("SET Successful!");
                setOpenSnackbar(true); 

                setSuccess(true);
                setLoading(false);
            },
            (error) => {
                console.log("UserService.setSession():", error)
                setMessageSnackbar("Error!");
                setOpenSnackbar(true); 
                setSuccess(true);
                setLoading(false);
            }
        );

     }

     const handleDeleteSession = (deviceID: String, rfID: String ) => {
        setSuccess(false);
        setLoading(true);
         UserService.deleteSession(deviceID, rfID).then(
            (data) => {
                setDataReport([...dataReport.filter(item => item.rfID !== rfID)]);

                setMessageSnackbar("DELETE Successful!");
                setOpenSnackbar(true); 
                setSuccess(true);
                setLoading(false);
            },
            (error) => {
                console.log("UserService.setSession():", error)
                setMessageSnackbar("Error!");
                setOpenSnackbar(true); 
                setSuccess(true);
                setLoading(false);
            }
        );
     }

    const handleChangePlan = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSessionsModal(event.target.value as number);
    };

 
    React.useEffect(() => {
    	console.log("sessionsModal", sessionsModal);
    	console.log("...............dataReport", dataReport);

        handleReport(sessionsModal);

        return () => {
            setDataReport([]);
        };

    }, [sessionsModal]);

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={sessionsModal == ReportModal.NOT_OPEN ? false : true}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" className={classes.reportControl}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                        <Grid item>
                            <FormControl className={classes.reportControlItem}>
                                <InputLabel id="plan-report-select-label">Vehicle</InputLabel>
                                <Select
                                    labelId="plan-report-select-label"
                                    id="plan-report-select"
                                    value={sessionsModal}
                                    onChange={handleChangePlan}
                                    className={classes.reportSelectPlan}
                                >{
                                    state.plans.map((plan: PlanType) => <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>)
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.reportControlItem}>
							<form className={classes.root} noValidate autoComplete="off">
								<FormControl>
								  <InputLabel htmlFor="component-helper">RfID</InputLabel>
								  <Input
								    id="input-rfid"
								    type="number"
                                    required
								   inputProps={{ maxLength: 10, minLength: 10 }}
								    value={inputRfID}
								    onChange={handleRfIDChange}
								  />
								</FormControl>
							</form>
                           
                        </Grid>

                        <Grid item className={classes.reportControlItem}>
                            <form noValidate>
                              <TextField
                                id="datetime-local"
                                label="Expired Date"
                                type="datetime-local"
                                className={classes.dateTimeField}
                                value={selectedExpiredDate}
                                onChange={event => handleSelectedExpiredDate(event.target.value as unknown as Date)} 
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </form>
                        </Grid>
                        <Grid item className={classes.reportControlItem}>
                            <Button  variant="outlined" color="primary"
                                    disabled={loading} 
                                    onClick={() => handleSetSession(sessionsModal)}>SET Session</Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataReport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.orderId}>
                                                {columns.map((column) => {
                                                	const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                         
                                                        { column.id == 'action'  ? <Button disabled={loading}  onClick={() => { handleDeleteSession(row.deviceID, row.rfID) }} variant="contained" color="secondary">Delete</Button> : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={dataReport.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="info">
                    {messageSnackbar}
                    </Alert>
                </Snackbar>
            </Dialog>
        </React.Fragment>
    );
}
