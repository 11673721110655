import React, {Dispatch, useEffect, useReducer, useRef} from 'react';
import L, {LatLngTuple} from "leaflet";
import {
    CurrentPlanActions,
    currentPlanReducer,
    mapVehiclesReducer,
    PlanActions,
    planReducer,
    VehicleActions
} from './AppReducer'
import {newPlanType, PlanType, VehicleType} from "./AppTypes";
import AuthService from "../_services/auth.service";
import {ReportModal} from '../_constants/const';

import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

require("leaflet.icon.glyph");
require("leaflet.locatecontrol");
require('leaflet.markercluster');


type InitialStateType = {
    plans: PlanType[];
    currentPlan: PlanType;
    mapVehicles: Map<string, VehicleType>; //key is deviceID
};

const initialState = {
    plans: [],
    currentPlan: newPlanType,
    mapVehicles: new Map<string, VehicleType>(),
};

const AppContext: any = React.createContext<{ state: InitialStateType; dispatch: Dispatch<PlanActions | CurrentPlanActions> }>({
    state: initialState,
    dispatch: () => null
});

const mainReducer = ({
                         plans,
                         currentPlan,
                         mapVehicles
                     }: InitialStateType, action: PlanActions | CurrentPlanActions | VehicleActions) => ({
    plans: planReducer(plans, action),
    currentPlan: currentPlanReducer(currentPlan, action),
    mapVehicles: mapVehiclesReducer(mapVehicles, action),
});

const AppProvider = ({children}: any) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    // store planID when click Menu of Plan OR just open plan report Modal
    const [planReportModal, setPlanReportModal] = React.useState(ReportModal.NOT_OPEN);

    // store planID when click Menu of Plan OR just open alert report Modal
    const [alertReportModal, setAlertReportModal] = React.useState(ReportModal.NOT_OPEN);

    // store planID when click Menu of Plan OR just open session Modal
    const [sessionsModal, setSessionsModal] = React.useState(ReportModal.NOT_OPEN);

    const [settingModal, setSettingModal] = React.useState(ReportModal.NOT_OPEN);

    const [route, setRoute] = React.useState({});

    const [user, setUser] = React.useState(AuthService.getCurrentUser());

    const defaultLatLng: LatLngTuple = [16.06065771599406, 108.19189380726841];

    const mapRef = useRef<L.Map | null>(null)
    const markersCluster = useRef<L.MarkerClusterGroup | null>(null)

    // FeatureGroup is to store editable layers
    const drawnItemsRef = useRef<L.FeatureGroup | null>(null);
    // @ts-ignore
    const drawControlRef = useRef<L.Control.Draw | null>(null);


    useEffect(() => {
        mapRef.current = L.map('mapId', {
            center: defaultLatLng,
            zoom: 6,
            maxZoom: 18,
            minZoom: 6,
            zoomControl: false,
            attributionControl: false,
            layers: [
                L.tileLayer("https://www.google.com/maps/vt?lyrs=m@189&gl=vn&x={x}&y={y}&z={z}", {
                    attribution:
                        '&copy; <a href="https://www.google.com/maps">Maps</a> contributors'
                })
            ]
        })
        L.control.scale({imperial: false}).setPosition("bottomright").addTo(mapRef.current);
        L.control.zoom({position: 'bottomright'}).addTo(mapRef.current)
        L.control.locate({position: 'bottomright'}).addTo(mapRef.current);

        markersCluster.current = L.markerClusterGroup({
            'chunkedLoading': true,
            'showCoverageOnHover': false,
            'disableClusteringAtZoom': 17,
            'iconCreateFunction': function (cluster) {
                return new L.DivIcon({
                    html: '<div class="icon-cluster">' + cluster.getChildCount() + '</div>'
                });
            }
        });

        mapRef.current.addLayer(markersCluster.current);

        // Draw control
        drawnItemsRef.current = L.featureGroup().addTo(mapRef.current);
        // @ts-ignore
        drawControlRef.current = new L.Control.Draw({
            draw: {
                polyline: false,
                marker: false,
                rectangle: false,
                circlemarker: false,
            },
            edit: {
                featureGroup: drawnItemsRef.current,
            }
        });
    }, [])

    const providerValue = {
        mapRef, markersCluster
        , drawnItemsRef, drawControlRef
        , state, dispatch
        , drawerOpen, setDrawerOpen
        , route, setRoute
        , user, setUser
        , planReportModal, setPlanReportModal
        , alertReportModal, setAlertReportModal
        , sessionsModal, setSessionsModal
        , settingModal, setSettingModal
    };

    return (
        <AppContext.Provider value={providerValue}>
            {children}
        </AppContext.Provider>
    )
}

export {AppContext, AppProvider};