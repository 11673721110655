import React, {useEffect} from 'react';
// import { Map, TileLayer, LayerGroup, ZoomControl } from 'react-leaflet';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import '../_lib/leaflet-control-geocoder';
import '../_lib/leaflet-routing-machine';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ListPlan from "../Plan/ListPlan";
import Plan from "../Plan/Plan";
import AddPlan from "../Plan/AddPlan";
import SignIn from "../SignIn/SignIn";
import MapApp from "./MapApp";
import PlanReportDialogs from "./PlanReport";
import AlertReportDialogs from "./AlertReport";
import SessionsDialogs from "./Sessions";
import Setting from "./Setting";
import {CRoutes} from "../_constants/routes";

import {PrivateRoute} from '../_components';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'stretch',
        },
        leftContent: {
            flex: '0 0 400px',
        },
        rightContent: {
            flexBasis: `calc(100vw - 400px)`,
        },
        paper: {
            padding: theme.spacing(0),
            color: theme.palette.text.secondary,
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
        drawerPaper: {
            width: '400px',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
        },
        typography: {
            padding: theme.spacing(2),
        },
    }),
);

interface State {
    startingpoint: string;
    destination: string;
}

const MainApp: React.FC = () => {
    const classes = useStyles();

    // TODO: this call multiple times

    useEffect(() => {
    }, []);

    return <div className={classes.root}>
        <div className={classes.leftContent}>
            <Drawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
            >
                <Router>
                    <Route path={CRoutes.SignIn} component={SignIn} exact/>
                    <PrivateRoute exact path={CRoutes.Plans} component={ListPlan}/>
                    <PrivateRoute path={CRoutes.AddPlan} component={AddPlan} exact/>
                    <PrivateRoute path={CRoutes.APlan} component={Plan} exact/>
                </Router>
            </Drawer>
        </div>

        <div className={classes.rightContent}>
            <MapApp/>
        </div>
        <PlanReportDialogs/>
        <AlertReportDialogs/>
        <SessionsDialogs/>
        <Setting/>
    </div>
}

export default MainApp;