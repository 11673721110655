import {LatLng, Marker} from "leaflet";


export const newPlanType = {
    id: 0,
    name: "",
    description: "",
    instructions: "",
    createdAt: "",
    totalDistance: 0,
    totalSecond: 0,
    waypoints: [],
    planVehicles: [],
    planGeoExceptions: [],
}

export type PlanType = {
    id: number;
    name: string;
    description: string;
    instructions: string;
    createdAt: string;
    totalDistance: number;
    totalSecond: number;
    waypoints: LatLng[];
    planVehicles: PlanVehicleType[];
    planGeoExceptions: PlanGeoException[];
};

export type VehicleType = {
    id: string;
    name: string;
    latitude?: number;
    longitude?: number;
    unixtime?: number;
    angle?: number;
    status?: number;
    opendoor?: number;
    km?: number;
    fuel?: number;
    datetime?: string;
    desc?: string;
    duration?: string;
    driver?: string;
    marker?: Marker;
}

export type PlanVehicleType = {
    deviceID: string,
    deviceName: string,
}

export enum Types {
    SavePlan = "SAVE_PLAN",
    DeletePlan = "DELETE_PLAN",
    ListPlan = "LIST_PLAN",
    GetPlan = "GET_PLAN",
    CreatePlan = "CREATE_PLAN",
    UpdatePlan = "UPDATE_PLAN",
    EmptyPlan = "EMPTY_PLAN",
    SaveVehicles = "SAVE_VEHICLES",
    ListVehicle = "LIST_VEHICLE",
}


export type AlertType = {
    orderId: number;
    id: number;
    detect: string;
    latLng: string;
    address: string;
    planName: string;
    roadmapDeviceId: string;
    roadmapDeviceName: string;
    planId: number;
    roadmapId: number;
    unixtime: number;
}

export type SessionType = {
    orderId: number;
    deviceID: string;
    rfID: string;
    expiredAt: number;
    expiredDate: string;
    action: string;
}


export type PlanGeoException = {
    id: number,
    geoJson: object,
}